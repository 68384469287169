<template>
    <v-card variant="text">
        <v-toolbar flat>
            <v-toolbar-title>{{ t('Simuladores') }}</v-toolbar-title>
            <v-btn fab small color="primary" @click="newSimulador" class="ma-2">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ExportExcel :dataItems="simuladores" />
        </v-toolbar>
        <v-text-field class="mx-4 mt-4" v-model="search" label="Cerca" prepend-inner-icon="mdi-magnify" single-line
            variant="outlined" hide-details></v-text-field>
        <v-data-table-virtual :headers="headers" :items="simuladores" :search="search" :loading="loading">
            <template v-slot:[`item.action`]="{ item }">
                <v-icon small @click="editSimulador(item)">mdi-pencil</v-icon>
            </template>
            <template v-slot:loading>
                <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
            </template>
        </v-data-table-virtual>
    </v-card>
    <v-dialog v-model="editDialog" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ editedSimulador.id ? t('Editar') : t('Crear') }} {{ t('simulador')
                    }}</v-toolbar-title>
                <v-btn @click="save" prepend-icon="mdi-content-save">
                    {{ t('Guardar') }}
                </v-btn>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-text-field v-model="editedSimulador.nombre" :label="t('Nombre')"></v-text-field>
                <v-text-field v-model="editedSimulador.descripcion" :label="t('Descripción')"></v-text-field>
                <v-text-field v-model="editedSimulador.num_alumnos" :label="t('Número_de_alumnos')"
                    type="number"></v-text-field>
                <v-text-field v-model="editedSimulador.num_docentes" :label="t('Número_de_docentes')"
                    type="number"></v-text-field>

                <v-row v-if="editedSimulador.id">
                    <v-col cols="12" sm="6" class="d-flex justify-center align-center pa-5">
                        <Calendar :attributes="formattedEvents" @update:pages="pageChange" />
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-5">
                        <v-btn block class="ma-3" @click="openTimeSlotDialog()">{{ t('Crear') }} TimeSlots...</v-btn>
                        <v-btn block class="ma-3" @click="openDeleteConfirmSDialog()"
                            prepend-icon="mdi-calendar-remove">{{
                                t('Eliminar') }}
                            TimeSlots...
                        </v-btn>
                        <v-btn block class="ma-3" color="red darken-1" text @click="openDeleteConfirmDialog()">{{
                            t('Eliminar') }}
                            simulador</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="timeSlotDialog" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ t('Crear') }} TimeSlots de {{ editedSimulador.nombre }}</v-toolbar-title>
                <v-btn icon @click="closeTimeSlotDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-card class="pa-5 ma-3">
                    <v-card-title>{{ t('Duración_de_la_actividad') }}</v-card-title>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-select :label="t('Horas')" :items="durada" v-model="timeSlotData.hours"></v-select>
                        </v-col>
                        <v-col cols="12" sm="6"><v-select :label="t('Minutos')" :items="rules.minutes"
                                v-model="timeSlotData.minutes"></v-select></v-col>
                    </v-row>
                </v-card>
                <v-card class="pa-5 ma-3">
                    <v-card-title>{{ t('Fecha_y_hora_de_inicio') }}</v-card-title>
                    <v-row>
                        <v-col cols="12" sm="6" class="d-flex justify-center align-center pa-5">
                            <DatePicker v-model.range="range" mode="date" :min-date='new Date()'>
                            </DatePicker>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-5">
                            <v-col cols="12" class="d-flex justify-center align-center">
                                <DatePicker v-model="range.start" mode="time" :rules="rules" is24hr>
                                </DatePicker>
                            </v-col>
                            <v-checkbox v-model="timeSlotData.weekly" label="Repetir semanalmente"></v-checkbox>
                            <v-checkbox v-model="timeSlotData.daily"
                                label="Repetir diariamente (laborables)"></v-checkbox>
                            <v-btn block @click="generateTimeSlots">{{ t('Crear') }} TimeSlots</v-btn>
                        </v-col>
                    </v-row>
                </v-card>

            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteConfirmDialog" persistent max-width="500px">
        <v-card>
            <v-card-title>{{ t('Estás_seguro') }}</v-card-title>
            <v-card-text>
                {{ t('Estás_seguro_que_quieres_eliminar') }} {{ t('el_simulador') }} "{{ editedSimulador.nombre }}"? {{
                    t('Esta_acción_es_irreversible') }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="showDeleteConfirmDialog = false">{{ t('Cancelar') }}</v-btn>
                <v-btn color="red darken-1" text @click="confirmDeleteSimulador">{{ t('Eliminar') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteConfirmSDialog" persistent max-width="500px">
        <v-card>
            <v-card-title>{{ t('Estás_seguro') }}</v-card-title>
            <v-card-text>
                <v-select v-model="selectedTimeslot" :items="timeSlots" item-title="inicio" item-value="id"
                    label="Select" persistent-hint return-object single-line></v-select>
                {{ t('Estás_seguro_que_quieres_eliminar') }} {{ t('el_timeslot') }} "{{ selectedTimeslot.inicio }}"? {{
                    t('Esta_acción_es_irreversible') }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="showDeleteConfirmSDialog = false">{{ t('Cancelar') }}</v-btn>
                <v-btn color="red darken-1" text @click="confirmDeleteTimeslot">{{ t('Eliminar') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { axios } from '../axios';
import { useStore } from 'vuex';
import ExportExcel from './ExportExcel.vue';
import { Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import { addMinutes, addHours } from 'date-fns';

const { t } = useI18n();
const store = useStore();
const selectedMonth = ref({ month: new Date().getMonth() + 1, year: new Date().getFullYear() }) // Default to current month/year
const range = ref({
    start: new Date(),
    end: new Date(),
});
const search = ref('');
const simuladores = ref([]);
const timeSlots = ref([]);
const formattedEvents = ref([]);

const headers = ref([
    { title: t('Nombre'), key: 'nombre' },
    { title: t('Descripción'), key: 'descripcion' },
    { title: t('Número_de_alumnos'), key: 'num_alumnos' },
    { title: t('Número_de_docentes'), key: 'num_docentes' },
    { title: t('Acciones'), key: 'action', sortable: false },
]);
const snackbarText = ref('');
const editDialog = ref(false);
const timeSlotDialog = ref(false);
const editedSimulador = ref({});
const selectedTimeslot = ref({ id: null, inicio: null });
const timeSlotData = ref({
    daily: false,
    weekly: false,
    hours: 1,
    minutes: 0,
});
const loading = ref(false);
const loading2 = ref(false);
const isLoading = ref(false);
const showDeleteConfirmDialog = ref(false);
const showDeleteConfirmSDialog = ref(false);
const rules = ref({
    hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
    minutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
});
const durada = ref([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

function fetchSimuladores() {
    loading.value = true;
    axios.get('simuladores/')
        .then(response => {
            simuladores.value = response.data;
        })
        .catch(error => {
            console.error('Error fetching simuladores:', error);
        })
        .finally(() => {
            loading.value = false;
        });
}

function pageChange(e) {
    console.log('pageChange', e);
    if (e[0].month !== selectedMonth.value.month) {
        console.log(`month: ${selectedMonth.value.month} -> ${e[0].month}`);
        selectedMonth.value.month = e[0].month;
        selectedMonth.value.year = e[0].year;
        fetchTimeSlots();
    }
}

function fetchTimeSlots() {
    isLoading.value = true; // Activar el estado de carga
    axios.get(`time-slots/?simulador=${editedSimulador.value.id}&month=${selectedMonth.value.month}&year=${selectedMonth.value.year}`, {
    }).then(response => {
        timeSlots.value = response.data;
    }).catch(error => {
        console.error("Error al cargar los time slots:", error);
    }).finally(() => {
        isLoading.value = false; // Desactivar el estado de carga
        formatEvents();
    });
}

function formatEvents() {
    formattedEvents.value = timeSlots.value.map(event => {
        // Convertir las fechas de inicio y fin a objetos Date
        const inicioDate = new Date(event.inicio);
        const finDate = new Date(event.fin);

        const options = { hour: '2-digit', minute: '2-digit' };
        const inicioStr = inicioDate.toLocaleTimeString([], options);
        const finStr = finDate.toLocaleTimeString([], options);

        console.log('inicioDate', inicioDate);
        console.log('inicioStr', inicioStr);

        return {
            highlight: {
                color: 'purple'
            },
            customData: { curso: event.curso, alumnos: event.alumnos, docentes: event.docentes },
            dates: inicioDate,
            popover: {
                label: `${inicioStr} - ${finStr}`
            },
        };
    });
}



function editSimulador(simulador) {
    editedSimulador.value = { ...simulador };
    fetchTimeSlots();
    editDialog.value = true;
}

function newSimulador() {
    Object.assign(editedSimulador.value, { nombre: '', descripcion: '', num_alumnos: 0, num_docentes: 0 });
    editDialog.value = true;
}

function save() {
    const method = editedSimulador.value.id ? 'put' : 'post';
    const url = editedSimulador.value.id ? `simuladores/${editedSimulador.value.id}/` : 'simuladores/';
    axios[method](url, editedSimulador.value)
        .then(() => {
            fetchSimuladores();
            editDialog.value = false;
            snackbarText.value = editedSimulador.value.nombre + ' ' + 'fue actualizado con éxito';
            store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'success' });
        })
        .catch(error => {
            store.dispatch('triggerSnackbar', { message: error, color: 'error' });
            console.error('Error saving the simulador:', error);
        });
}

const openDeleteConfirmDialog = () => {
    showDeleteConfirmDialog.value = true;
};

const openDeleteConfirmSDialog = () => {
    showDeleteConfirmSDialog.value = true;
};

const confirmDeleteSimulador = () => {
    loading2.value = true;
    axios.delete(`simuladores/${editedSimulador.value.id}`)
        .then(() => {
            editDialog.value = false;
            showDeleteConfirmDialog.value = false;
            snackbarText.value = t("se_ha_eliminado_con_éxito");
            store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'success' });
            fetchSimuladores();
            loading2.value = false;
        })
        .catch(error => {
            store.dispatch('triggerSnackbar', { message: error, color: 'error' });
            loading2.value = false;
        });
};

const confirmDeleteTimeslot = () => {
    loading2.value = true;
    axios.delete(`timeslots/${selectedTimeslot.value.id}`)
        .then(() => {
            editDialog.value = false;
            showDeleteConfirmSDialog.value = false;
            snackbarText.value = t("se_ha_eliminado_con_éxito");
            store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'success' });
            fetchTimeSlots();
            loading2.value = false;
        })
        .catch(error => {
            store.dispatch('triggerSnackbar', { message: error, color: 'error' });
            loading2.value = false;
        });
};

function close() {
    editDialog.value = false;
}

function openTimeSlotDialog() {
    timeSlotDialog.value = true;
    // Asegúrate de inicializar o restablecer los valores de timeSlotData aquí si es necesario
}

function closeTimeSlotDialog() {
    timeSlotDialog.value = false;
}

async function generateTimeSlots() {
    if (!range.value.start) {
        alert(t('Por favor, rellena las horas de inicio y duración TimeSlot'));
        return;
    }
    let endDatetime = new Date(range.value.start);
    endDatetime = addHours(endDatetime, timeSlotData.value.hours);
    endDatetime = addMinutes(endDatetime, timeSlotData.value.minutes);
    console.log('endDatetime', endDatetime);

    const payload = {
        simulador_id: editedSimulador.value.id,
        start_datetime: range.value.start,
        end_datetime: endDatetime,
        end_repeat_date: range.value.end,
        repeat_daily: timeSlotData.value.daily,
        repeat_weekly: timeSlotData.value.weekly,
    };

    try {
        const response = await axios.post('gen_timeslots/', payload);
        if (response.data.status === 'success') {
            alert(t('TimeSlots creados con éxito!'));
        } else {
            alert(t(response.data.message));
        }
        closeTimeSlotDialog();
    } catch (error) {
        console.error('Error al generar TimeSlots:', error);
        alert(t('Error al generar TimeSlots'));
    }
}


onMounted(() => {
    fetchSimuladores();
});
</script>
