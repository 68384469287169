<template>
    <v-row>
        <v-col cols="12">
            <v-skeleton-loader :loading="loading" type="list-item-two-line">
                <v-list lines="two" v-if="userReservations.length">
                    <v-list-item v-for="reservation in userReservations" :key="reservation.id">
                        <v-list-item-title>
                            {{ reservation.simulador_nombre }} ({{ reservation.curso.titulo }})
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ formatReservation(reservation) }}
                        </v-list-item-subtitle>
                        <template v-slot:append>
                            <!-- Botón para mostrar el QR -->
                            <v-btn class="ma-2" small color="green" @click="showQrCode(reservation)">
                                <v-icon>mdi-qrcode</v-icon>
                                <span class="d-none d-sm-inline">{{ t('Código_QR') }}</span>
                            </v-btn>
                            <!-- Botón de cancelar reserva -->
                            <v-btn small color="error" outlined @click="cancelReservation(reservation)">
                                <v-icon>mdi-cancel</v-icon>
                                <span class="d-none d-md-inline">{{ t('Cancelar_reserva') }}</span>
                            </v-btn>
                        </template>
                    </v-list-item>
                </v-list>
                <!-- Mensaje cuando no hay reservas -->
                <v-alert v-else color="info">
                    {{ t('No_tienes_ningún_simulador_reservado') }}
                </v-alert>
            </v-skeleton-loader>
        </v-col>
    </v-row>

    <!-- Modal para el QR -->
    <v-dialog v-model="showQrModal" persistent max-width="600">
        <v-card>
            <template v-if="!consent">
                <v-card-text class="text-center">
                    <p>{{ t('Acepto_VH') }}</p>
                    <p>{{ t('Para_más_detalles') }} <a href="https://www.vallhebron.com/politica-de-proteccio-de-dades"
                            target="_blank">{{
                                t('Política_VH') }}</a> {{ t('y_la') }} <a
                            href="https://qrsimulacio.vallhebron.cat/imatge.html" target="_blank">{{
                                t('Cesion_de_derechos') }}</a>.</p>
                </v-card-text>
            </template>
            <template v-if="consent">
                <div id="qr-code-container" ref="qrCodeContainer">
                    <v-card-title class="text-center mt-3">{{ t('Código_QR_de_acceso') }}</v-card-title>
                    <v-card-text class="text-center">
                        <vue-qrcode :value="currentQrValue"></vue-qrcode>
                        <div>{{ currentReservationText }}</div>
                    </v-card-text>
                </div>
            </template>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!consent" color="secondary" text @click="accept">{{ t('Aceptar') }}</v-btn>
                <v-btn v-if="consent" color="secondary" text @click="downloadQr">{{ t('Descargar') }}</v-btn>
                <v-btn color="primary" text @click="closeQrModal">{{ t('Cerrar') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Modal para el Confirmar cancelación -->
    <v-dialog v-model="showConfirmDialog" persistent max-width="300">
        <v-card>
            <v-card-title class="text-center mt-3">{{ t('Confirmación') }}</v-card-title>
            <v-card-text>{{ t('Seguro_cancelar_reserva') }} <strong>{{ currentReservationText }}</strong>?</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" text @click="confirmCancellation">{{ t('Sí') }}</v-btn>
                <v-btn color="red" text @click="showConfirmDialog = false">{{ t('No') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import html2canvas from 'html2canvas';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { axios, setAuthToken } from '../axios';
import { useI18n } from 'vue-i18n';
export default {
    emits: ['reservationCancelled'],
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    data() {
        return {
            userReservations: [],
            showQrModal: false,
            consent: false,
            currentQrValue: '',
            showConfirmDialog: false,
            reservationIdToCancel: null,
            loading: true,
        };
    },
    components: {
        VueQrcode
    },

    methods: {
        accept() {
            this.consent = true;
        },
        showQrCode(reservationId) {
            this.currentQrValue = this.qrCodeValue(reservationId);
            this.currentReservationText = this.formatReservation(reservationId);
            this.showQrModal = true;
        },
        closeQrModal() {
            this.showQrModal = false;
        },
        qrCodeValue(reservation) {
            // Aquí debes generar el valor del QR basado en el ID de la reserva
            // Por ejemplo:
            return `${reservation.codiqr}`;
        },
        fetchUserReservations() {
            this.loading = true
            axios.get(`user_reservations/`, {
            }).then(response => {
                // Filtrar solo las reservas futuras
                const now = new Date();
                this.userReservations = response.data.filter(reservation => new Date(reservation.inicio) > now);
                this.loading = false
            });
        },
        cancelReservation(reservationId) {
            this.reservationIdToCancel = reservationId.id;
            this.currentReservationText = this.formatReservation(reservationId);
            this.showConfirmDialog = true;
        },
        confirmCancellation() {
            axios.delete(`cancel_reservation/${this.reservationIdToCancel}/`, {
            }).then(() => {
                this.$emit('reservation-cancelled', this.reservationIdToCancel);
                alert("Reserva cancelada exitosamente");
                this.fetchUserReservations();
                this.showConfirmDialog = false;
            }).catch(error => {
                console.error("Error al cancelar la reserva:", error);
                alert("Error al cancelar la reserva");
                this.showConfirmDialog = false;
            });
            this.reservationIdToCancel = null;
        },

        formatReservation(reservation) {
            const startDate = new Date(reservation.inicio);
            const endDate = new Date(reservation.fin);
            return `${startDate.toLocaleDateString()} de ${startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} a ${endDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
        },
        downloadQr() {
            html2canvas(this.$refs.qrCodeContainer).then(canvas => {
                // Crear un elemento <a> para descargar la imagen
                let link = document.createElement('a');
                link.download = 'qr-code.png';
                link.href = canvas.toDataURL('image/png');
                link.click();
            }).catch(err => {
                console.error('Error al capturar QR: ', err);
            });
        },
    },
    created() {
        this.fetchUserReservations();
    },
    mounted() {
        const token = localStorage.getItem('authToken');
        setAuthToken(token);
    }
};
</script>