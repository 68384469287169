<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="12" md="8">
        <v-card class="ma-4 pa-2">
          <v-card-title>{{ t('Registro') }}</v-card-title>
          <v-card-text>
            <HelperCarregant v-if="loading" :missatge="t('Creando') + ' ' + t('usuario')" />
            <template v-if="!loading">
              <form @submit.prevent="register">
                <v-text-field :label="t('Email_institucional')" v-model="email" required type="email" ref="emailInput"></v-text-field>
                <v-text-field v-model="first_name" :label="t('Nombre')" required></v-text-field>
                <v-text-field v-model="last_name" :label="t('Apellidos')" required></v-text-field>
                <v-text-field v-model="fecha_inicio_especialidad" :label="t('Fecha_inicio_especialidad')" type="date"
                  required></v-text-field>
                <v-autocomplete v-model="tipo_profesional" :items="tiposProfesionales" item-title="categoria"
                  item-value="id" :label="t('Profesional')"></v-autocomplete>

                <v-text-field :label="t('Contraseña')" v-model="password1" required type="password"
                  ref="passwordInput"></v-text-field>

                <v-text-field :label="t('Vuelve_a_escribir_la_c')" v-model="password2" required type="password"
                  ref="passwordInput"></v-text-field>

                <v-checkbox :label="t('He_leído_gineSIM')" v-model="consent" required></v-checkbox>

                <v-btn class="mt-5" type="submit" color="primary" :disabled="loading">{{ t('Registrarse') }}</v-btn>
              </form>
            </template>
          </v-card-text>
          <v-card-actions class="justify-center">
            <span>{{ t('Ya_tienes_una_cuenta') }}</span>
            <v-btn text small color="primary" :disabled="loading" @click="$router.push('/login')">{{ t('Iniciar_sesión')
              }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { axios } from '../axios';
import HelperCarregant from './HelperCarregant.vue';
import { useI18n } from 'vue-i18n';
export default {
  components: {
    HelperCarregant,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  },
  data() {
    return {
      first_name: '',
      last_name: '',
      fecha_inicio_especialidad: '',
      tipo_profesional: '',
      email: '',
      password1: '',
      password2: '',
      consent: false,
      tiposProfesionales: [],
      loading: false,
      snackbarText: '',
    };
  },
  methods: {
    async fetchProfesionales() {
      try {
        const response = await axios.get(`profesionales/`, {
        });

        this.tiposProfesionales = response.data.sort((a, b) => a.categoria.localeCompare(b.categoria));
      } catch (error) {
        console.error("Error al obtener las categorías de profesionales:", error);
      }
    },
    register() {
      this.loading = true;
      axios.post(`dj-rest-auth/registration/`, {
        first_name: this.first_name,
        last_name: this.last_name,
        fecha_inicio_especialidad: this.fecha_inicio_especialidad,
        profesional: this.tipo_profesional,
        email: this.email,
        password1: this.password1,
        password2: this.password2,
      })
        .then(response => {
          if (response.data.detail) {
            this.snackbarText = response.data.detail;  // Mostrará: "Se ha enviado un correo de verificación."
            this.loading = false;
            this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'success' });
            this.$router.push('/login');
          }
        })
        .catch(error => {
          if (error.response) {
            // Check if the error message is in the 'detail' attribute
            if (error.response.data.detail) {
              this.snackbarText = "Error: " + error.response.data.detail;
            }
            // Check if errors are field-specific
            else {
              let errorMessages = [];
              for (let field in error.response.data) {
                let errorsForField = error.response.data[field];
                errorMessages.push(`${errorsForField.join(', ')}`);
              }
              this.snackbarText = errorMessages.join(' ');
            }
          } else {
            // If no server response, it could be a network error or something else
            this.snackbarText = "An unexpected error occurred.";
          }
          this.loading = false;
          this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
        });
    },
  },
  mounted() {
    this.fetchProfesionales();
  }
}
</script>