<template>
  <v-container class="fill-height">
    <v-row no-gutters  justify="center">
      <v-col cols="12" md="10" class="pa-3 mt-3">
        <!-- Capçalera i Introducció -->
        <v-card>
          <v-card-title>Condiciones de Uso</v-card-title>
          <v-card-text>
            <p class="text-justify">
              1. <strong>Aceptación de las Condiciones:</strong> Al acceder y utilizar Simulacio.com, usted acepta y
              acuerda
              estar sujeto a estas condiciones de uso. Si no está de acuerdo con estas condiciones, no debe acceder
              ni
              utilizar Simulacio.com.
            </p>
            <p class="text-justify">
              2. <strong>Objetivo del Sitio:</strong> Simulacio.com es una plataforma web que sirve para gestionar
              cursos de
              formación teórico-práctica.
            </p>
            <p class="text-justify">
              3. <strong>Registro y Cuenta:</strong> Para acceder a algunos servicios, es necesario registrarse y
              proporcionar cierta información. El usuario se compromete a proporcionar información verdadera, exacta
              y
              completa. Simulacio.com se reserva el derecho de suspender o cancelar cuentas que consideremos que han
              proporcionado información falsa.
            </p>
            <p class="text-justify">
              3. <strong>Contenido Proporcionado por los Usuarios:</strong> Todos los usuarios de este sitio son
              responsables de asegurarse de que el contenido que suben, comparten o de otra manera hacen disponible
              en Simulacio.com, incluyendo textos, fotografías, gráficos, y material audiovisual, cumpla con las
              leyes de propiedad intelectual aplicables. Al subir contenido, los usuarios deben confirmar
              explícitamente que poseen los derechos necesarios o que han obtenido todas las licencias, derechos,
              consentimientos, y permisos necesarios para utilizar y autorizar a Simulacio.com a usar dicho
              contenido.
            </p>
            <p class="text-justify">
              4. <strong>Uso Apropiado:</strong> El usuario se compromete a utilizar Simulacio.com de acuerdo a las
              leyes y
              estas condiciones. Cualquier uso no autorizado resultará en la terminación de la cuenta.
            </p>
            <p class="text-justify">
              5. <strong>Limitaciones de Uso:</strong> Está prohibido distribuir, modificar, copiar, o usar el
              contenido
              de Simulacio.com para fines comerciales sin el permiso explícito.
            </p>
            <p class="text-justify">
              6. <strong>Cambios en las Condiciones:</strong> Simulacio.com se reserva el derecho de modificar estas
              condiciones en cualquier momento.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="10" class="pa-3">
        <v-card>
          <v-card-title>Política de Privacidad</v-card-title>
          <v-card-text>
            <p class="text-justify">
              1. <strong>Información Recopilada:</strong> Recolectamos la información que nos proporciona al
              registrarse,
              como nombre, email, y tiempo de experiencia en la especialidad. También guardamos el seguimiento de
              los
              cursos y las evaluaciones. Cuando accedes a una actividad los docentes de la misma tendrán acceso a tus datos.
            </p>
            <p class="text-justify">
              2. <strong>Uso de la Información:</strong> Usamos esta información para ofrecer y mejorar nuestros
              servicios, enviar comunicaciones relevantes y para fines administrativos.
            </p>
            <p class="text-justify">
              3. <strong>Protección de Datos:</strong> Estamos comprometidos a proteger la privacidad de sus datos.
              Implementamos medidas de seguridad adecuadas para proteger su información.
            </p>
            <p class="text-justify">
              4. <strong>Terceros:</strong> Los docentes de las actividades en las cuales participes podrán acceder a tus datos. Los administradores de las instituciones usuarias de Simulacio.com tambien podran acceder a tus datos.
            </p>
            <p class="text-justify">
              5. <strong>Códigos QR:</strong> Simulacio.com permite generar códigos QR con tus datos personales que puedes utilizar para transferir fácilmente tus datos a bases de datos de terceros. Pasando el código QR por el lector de dichos terceros, aceptas dicha transferencia de datos.
            </p>
            <p class="text-justify">
              6. <strong>Derechos:</strong> Puede acceder, corregir, o solicitar la eliminación de su información
              personal
              en cualquier momento contactándonos.
            </p>
            <p class="text-justify">
              7. <strong>Cambios a la Política:</strong> Podemos modificar esta política de privacidad
              ocasionalmente. Las
              modificaciones entrarán en vigor una vez publicadas en el sitio.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="10" class="pa-3 mb-3">
        <v-card>
          <v-card-title>Política de Cookies</v-card-title>
          <v-card-text>
            <p class="text-justify">
              1. <strong>Uso de Cookies:</strong> Simulacio.com utiliza cookies para mejorar la experiencia del
              usuario y
              asegurar su correcto funcionamiento. Las cookies son pequeños archivos de texto que se almacenan en su
              dispositivo cuando visita nuestro sitio.
            </p>
            <p class="text-justify">
              2. <strong>Tipo de Cookies:</strong> Utilizamos cookies esenciales que son necesarias para el
              funcionamiento
              básico de nuestro sitio web, como mantener a los usuarios autenticados. Estas cookies no recopilan
              información personal y no se utilizan para rastrear su comportamiento en otros sitios web.
            </p>
            <p class="text-justify">
              3. <strong>Control de Cookies:</strong> Puede configurar su navegador para rechazar todas o algunas
              cookies
              o para alertarle cuando se envían sitios web. Sin embargo, si desactiva o rechaza cookies, tenga en
              cuenta
              que algunas partes de nuestro sitio pueden no funcionar correctamente.
            </p>
            <p class="text-justify">
              4. <strong>Consentimiento:</strong> Al continuar navegando y utilizando nuestro sitio, acepta el uso
              de
              estas cookies esenciales.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>